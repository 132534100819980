<template>
    <div class="AI-Smart-Room-pc">
        <div class="pc-header">
            <img src="../images/pc/AI-logo.png" width="150px">
            <el-tabs v-model="activeName">
                <el-tab-pane label="产品介绍" name="productIntro"></el-tab-pane>
                <el-tab-pane label="招商加盟" name="joinUs"></el-tab-pane>
            </el-tabs>
        </div>
        <div v-show="activeName=='productIntro'" class="content-prodIntro">
            <template v-if="activeName=='productIntro'">
                <img v-for="(item,index) in productIntroImages" :key="index" :src="item" width="100%" height="auto">
            </template>
        </div>
        <div v-show="activeName=='joinUs'" class="content-joinus">
            <template v-if="activeName=='joinUs'">
                <img v-for="(item,index) in joinUsImages" :key="index" :src="item" width="100%" height="auto">
            </template>
        </div>
        <div ref="qrRef" class="qr-container" :style="qrPos">
            <div>期待与您合作</div>
            <img src="../images/qrcode.png" width="115px" height="115px">
            <div class="scan-code">扫码添加合作顾问</div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'mobile',
    data () {
        return {
            activeName: 'productIntro',
            productIntroImages: [
                require('../images/mobile/m_zh_1.png'),
                require('../images/mobile/m_zh_2.png'),
                require('../images/mobile/m_zh_3.png'),
                require('../images/mobile/m_zh_4.png'),
                require('../images/mobile/m_zh_5.png'),
                require('../images/mobile/m_zh_6.png'),
                require('../images/mobile/m_zh_7.png'),
                require('../images/mobile/m_zh_8.png'),
                require('../images/mobile/m_zh_9.png'),
                require('../images/mobile/m_zh_10.png'),
                require('../images/mobile/m_zh_11.png'),
                require('../images/mobile/m_zh_12.png'),
                require('../images/mobile/m_zh_13.png'),
                require('../images/mobile/m_zh_14.png'),
                require('../images/mobile/m_zh_15.jpg'),
            ],
            joinUsImages: [
                require('../images/mobile/m_zs-01.jpg'),
                require('../images/mobile/m_zs-02.jpg'),
                require('../images/mobile/m_zs-03.jpg'),
                require('../images/mobile/m_zs-04.jpg'),
                require('../images/mobile/m_zs-05.jpg?v=20240703'),
            ],
            qrPos: {
                left: 'unset',
                top: 'unset',
                right: '10px',
                bottom: '20px',
            },
        };
    },
    watch: {
        activeName (val) {
            this.$nextTick(() => {
                const contentClassName = val === 'productIntro' ? '.content-prodIntro' : '.content-joinus';
                const scrollWarp = document.querySelector(`.AI-Smart-Room-pc ${contentClassName}`);
                scrollWarp.scrollTop = 0;
                const bar = document.querySelector('.AI-Smart-Room-pc .el-tabs__active-bar');
                if (bar) {
                    if (val === 'productIntro') {
                        bar.style.transform = `translateX(0px)`;
                    } else {
                        this.$nextTick(() => {
                            bar.style.transform = `translateX(80px)`;
                        });
                    }
                }
            });
        },
    },
    created () {
        const query = this.$route.query;
        if (query['tab'] && ['productIntro', 'joinUs'].includes(query['tab'])) {
            this.activeName = query['tab'];
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.$refs.qrRef.addEventListener('touchstart', this.onStartDrag);
        });
    },
    beforeDestroy () {
        this.$refs.qrRef.removeEventListener('touchstart', this.onStartDrag);
    },
    methods: {
        onStartDrag (e) {
            let touch = e.touches[0];
            document.body.addEventListener('touchmove', this.onDrag);
            document.body.addEventListener('touchend', this.onEndDrag);
            this.bodyRect = document.body.getBoundingClientRect();
            this.startDragPoint = { x: touch.clientX, y: touch.clientY, dx: this.$refs.qrRef.offsetLeft, dy: this.$refs.qrRef.offsetTop };
        },
        onDrag (e) {
            let touch = e.touches[0];
            const nx = touch.clientX - this.startDragPoint.x;
            const ny = touch.clientY - this.startDragPoint.y;
            let x = this.startDragPoint.dx + nx;
            let y = this.startDragPoint.dy + ny;
            if (x < 0) x = 0;
            if (x > this.bodyRect.width - 130) x = this.bodyRect.width - 130;
            if (y < 0) y = 0;
            if (y > this.bodyRect.height - 180) y = this.bodyRect.height - 180;
            Object.assign(this.qrPos, {
                left: x + 'px',
                top: y + 'px',
                right: 'unset',
                bottom: 'unset',
            });
        },
        onEndDrag (e) {
            document.body.removeEventListener('touchmove', this.onDrag);
            document.body.removeEventListener('touchend', this.onEndDrag);
        },
    },
};
</script>

<style scoped lang="scss">
.AI-Smart-Room-pc {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .pc-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 20px;
        background-color: #FFFFFF;
        box-shadow: 0 1px 8px 0 hsla(0, 0%, 78.4%, .4);
        position: relative;
        z-index: 1;
        ::v-deep .el-tabs__header {
            margin-bottom: 4px;
            .el-tabs__nav-wrap {
                .el-tabs__nav {
                    .el-tabs__active-bar {
                        background-color: #FFC136;
                        height: 3px;
                        width: 64px !important;
                    }
                    .el-tabs__item {
                        color: #242424;
                        font-size: 16px;
                        &.is-active {
                            color: #FFC136;
                        }
                        &#tab-productIntro {
                            padding-right: 8px;
                        }
                        &#tab-joinUs {
                            padding-left: 8px;
                        }
                    }
                }
                &:after {
                    display: none;
                }
            }
        }
    }
    .content-prodIntro, .content-joinus {
        width: 100%;
        flex: 1;
        overflow-y: auto;
        >img {
            display: block;
        }
    }
    .qr-container {
        position: fixed;
        z-index: 2;
        right: 10px;
        bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 130px;
        height: 180px;
        background: linear-gradient(177deg, #FFA72B, #FF8415);
        box-shadow: 4px 7px 16px 0px rgba(255,133,22,0.3);
        border-radius: 12px;
        padding-top: 8px;
        padding-bottom: 4px;
        color: #FFFFFF;
        font-size: 16px;
        user-select: none;
        * {
            pointer-events: none;
        }
        .scan-code {
            font-size: 13px;
        }
    }
}
</style>
